@import "../../../Styles/variables.scss";

.content {
  padding: 5rem 0 10rem;
  box-sizing: border-box;
  .content_wrapper,
  .ad_banner_wrapper {
    display: flex;
    gap: 2rem;
    & > div {
      width: 100%;
      background-color: #fff;
      border: 0.05rem solid $grey_1;
      .title {
        font-family: Bold;
        font-size: 2rem;
      }
    }
  }
  .content_wrapper {
    display: flex;
    & > div {
      padding: 3rem;
      box-sizing: border-box;
      border-radius: 2rem;
      .title_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 0.5rem;
        box-sizing: border-box;
        .more_btn {
          font-family: Bold;
          font-size: 1.2rem;
          color: $black_2;
          border: 0.1rem solid $black_4;
          padding: 0.5rem 1rem;
          box-sizing: border-box;
          border-radius: 5rem;
          min-width: max-content;
          background-color: #fff;
        }
      }
      .board_wrapper {
        padding-top: 1rem;
        box-sizing: border-box;
        .board {
          display: flex;
          align-items: center;
          gap: 1.6rem;
          padding: 1rem 0;
          box-sizing: border-box;
          .thumbnail {
            width: 8rem;
            height: 5rem;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .text,
          .text_wrapper {
            width: calc(100% - 8rem);
            .title {
              word-break: keep-all;
              font-size: 1.6rem;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: no-wrap;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
    }
    .column {
      .board_wrapper {
        .board {
          .thumbnail {
            width: 5rem;
            height: 5rem;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 50%;
            }
          }
          .text_wrapper {
            .series_name {
              font-family: Medium;
              font-size: 1.2rem;
              color: $blue_1;
              padding-bottom: 0.5rem;
              box-sizing: border-box;
            }
            .title {
              font-size: 1.6rem;
            }
          }
        }
      }
    }
  }
  .ad_banner_wrapper {
    margin-top: 2rem;
  }
}
