@import "../../Styles/variables.scss";

.wrapper {
  display: flex;
  justify-content: center;
  .search_wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    border: 0.1rem solid #c1c1c1;
    padding: 1rem 1.5rem;
    box-sizing: border-box;
    column-gap: 2rem;
    select,
    input {
      border: none;
      font-size: 1.6rem;
      font-family: Medium;
      color: $grey_3;
      background-color: transparent;
    }
    input {
      width: 100%;
      width: 40rem;
    }
    .submit_btn {
      cursor: pointer;
      width: 2rem;
      img {
        width: 100%;
        filter: invert(40%);
      }
    }
  }
}
