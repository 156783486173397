@import "../../Styles/variables.scss";

.input_wrapper {
  position: relative;
  input,
  select {
    width: 100%;
    border: 0.05rem solid rgb(219, 219, 219);
    border-radius: 1rem;
    padding: 2rem 1rem;
    box-sizing: border-box;
  }
  .right_icon_wrapper {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translate(0, -40%);
    img {
      width: 100%;
    }
  }
  select {
    cursor: pointer;
    font-family: "Medium";
  }
}

textarea {
  width: 100%;
  border: 0.05rem solid rgb(219, 219, 219);
  border-radius: 0.5rem;
  padding: 0.5rem;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
}
textarea::placeholder {
  color: grey;
}
