@import "../../Styles/variables.scss";

ul {
  padding: 0;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
  list-style: none;
  font-family: Medium;
  font-size: 1.4rem;
  img {
    display: block;
  }
  a {
    padding: 1rem 1.5rem;
    border-radius: 50%;
  }
  .activated {
    a {
      background-color: $blue_1;
      color: white;
      font-family: Bold;
    }
  }
}
