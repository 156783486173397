@import "../../../Styles/variables.scss";

.main_video_wrapper {
  border-bottom: 0.1rem solid $grey_1;
  padding: 4rem 0;
  box-sizing: border-box;
  a {
    display: inline-block;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      border-radius: 2rem;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px;
    }
  }
}
