@import "../../Styles/variables.scss";

.search_wrapper {
  top: 10.15rem;
  background-color: $grey_2;
  border-bottom: 0.1rem solid $grey_1;
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 1rem;
    padding: 4rem 0;
    box-sizing: border-box;
  }
  select,
  input {
    border-width: 0.2rem;
    border-color: $blue_3;
    color: $blue_2;
    font-size: 1.6rem;
    padding: 1rem;
    box-sizing: border-box;
  }
  select {
    font-family: "Regular";
  }
  input {
    font-family: "Bold";
    width: 50rem;
    &::placeholder {
      color: $blue_2;
    }
  }
}

@media screen and (max-width: 768px) {
  .search_wrapper {
    padding: 0 2rem;
    box-sizing: border-box;
    & > div {
      display: inline-block;
      padding: 2rem 0;
    }
    select {
      margin-bottom: 1rem;
    }
    input {
      width: 100%;
    }
  }
}
