@import "../../Styles/variables.scss";

.contact,
.contact > a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 1rem;
}
.contact {
  a {
    border-radius: 0.8rem;
    padding: 1rem 1.5rem;
    box-sizing: border-box;
    font-family: Bold;
    font-size: 1.5rem;
  }
  .website_btn {
    color: $grey_3;
    border: 0.1rem solid $grey_3;
  }
  .contact_btn {
    border: 0.1rem solid $blue_1;
    color: $blue_1;
  }
}
