.wrapper {
  border-radius: 2rem;
  background-color: white;
  .thumbnail {
    img {
      width: 100%;
      height: 30rem;
      object-fit: contain;
    }
  }
  .content {
    padding: 1rem 2rem 2rem;
    box-sizing: border-box;
    .title {
      font-family: Bold;
      font-size: 2rem;
      margin: 1rem 0;
      width: 41rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .text {
      font-family: Regular;
      font-size: 1.6rem;
      color: rgb(70, 70, 70);
      width: 100%;
      display: -webkit-box;
      word-break: keep-all;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 2.5rem;
    }
  }
}
