@import "../../Styles/variables.scss";

.black {
  background-color: black;
  color: #fff;
}
.blue {
  background-color: $blue_1;
  color: #fff;
}
.scroll_top_btn {
  cursor: pointer;
  position: fixed;
  right: 4rem;
  bottom: 4rem;
  img {
    width: 3.6rem;
    height: 3.6rem;
  }
}
