@import "../../Styles/variables.scss";

.label_wrapper {
  display: flex;
  align-items: center;
  column-gap: 2rem;
  .add_category {
    cursor: pointer;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    padding-bottom: 2rem;
    box-sizing: border-box;
    span {
      background-color: $blue_1;
      color: white;
      width: 2.4rem;
      height: 2.4rem;
      text-align: center;
      border-radius: 50%;
      font-family: Regular;
      font-size: 2.2rem;
      line-height: 2.2rem;
    }
    p {
      color: $blue_1;
      font-family: Medium;
      font-size: 1.6rem;
      padding: 0;
    }
  }
}
.flex_wrapper {
  width: max-content;
  display: flex;
  align-items: center;
  border: 0.1rem solid $grey_1;
  border-radius: 1rem;
  margin-bottom: 1rem;
  select {
    padding: 2rem 5rem 2rem 2rem;
    box-sizing: border-box;
    border: none;
    border-radius: 1rem;
  }
  .line {
    width: 0.1rem;
    height: 2.6rem;
    background-color: $grey_1;
    margin-left: 1rem;
  }
}
.circle_wrapper {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  input {
    max-width: 9.5rem;
    margin-right: 1rem;
    text-align: center;
  }
  .circle {
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background-color: black;
  }
}
.list_wrapper {
  .list_item {
    display: flex;
    align-items: center;
    column-gap: 2rem;
    padding: 1rem 0 0.5rem;
    box-sizing: border-box;
    span {
      font-family: Medium;
      font-size: 1.8rem;
    }
    .remove_icon {
      cursor: pointer;
      color: $black_4;
    }
  }
}

@media screen and (max-width: 768px) {
  .flex_wrapper {
    width: 100%;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
    border: none;
    & > div {
      border: 0.1rem solid $grey_1;
      border-radius: 1rem;
      width: 100%;
      padding-right: 2rem;
      box-sizing: border-box;
    }
    .line {
      display: none;
    }
  }
}
