@import "../../../Styles/variables.scss";

.video_list_wrapper {
  padding-top: 3rem;
  box-sizing: border-box;
  .sub_title {
    font-family: Bold;
    font-size: 2rem;
    padding-bottom: 2rem;
    box-sizing: border-box;
  }
  .video_list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 4rem;
    row-gap: 3rem;
    .video_wrapper {
      img {
        width: 100%;
        border-radius: 1.5rem;
      }
      .video_title {
        font-family: Medium;
        font-size: 1.8rem;
        word-break: keep-all;
        line-height: 2.4rem;
        padding: 1rem 0 0.5rem;
        box-sizing: border-box;
      }
      .video_date {
        font-family: Regular;
        font-size: 1.5rem;
        color: $black_4;
      }
    }
  }
}
