@import "../../Styles/variables.scss";

.content_wrapper {
  padding-top: 3rem;
  box-sizing: border-box;

  .navigation_wrapper {
    border-bottom: 0.1rem solid $grey_1;
    & > div {
      display: flex;
      column-gap: 6rem;
      padding-bottom: 0;
      .tab {
        cursor: pointer;
        font-family: Bold;
        font-size: 2rem;
        color: $black_4;
        padding-bottom: 1.5rem;
        box-sizing: border-box;
        &.activated {
          border-bottom: 0.3rem solid $blue_1;
        }
      }
    }
  }
  .main_content_wrapper {
    background-color: $blue_5;
    padding-bottom: 5rem;
    box-sizing: border-box;
    .content {
      background-color: white;
      border: 0.1rem solid $grey_1;
      border-radius: 2rem;
      padding: 5rem 3rem;
      box-sizing: border-box;
      margin-bottom: 1rem;
      .title {
        font-family: Bold;
        font-size: 3rem;
        padding-bottom: 2rem;
        box-sizing: border-box;
      }
      .text {
        font-family: Regular;
        font-size: 1.8rem;
        line-height: 3rem;
      }
    }

    /*     .gallery_wrapper {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 2rem;
      .img_wrapper {
        width: 100%;
        height: 30rem;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 2rem;
        }
      }
    } */
  }
}
