.app {
  a {
    text-decoration: none;
    color: unset;
  }
  p {
    padding: 0;
    margin: 0;
  }
  input {
    border: 0;
  }
  input[type="button"] {
    cursor: pointer;
  }
  input:focus,
  select:focus {
    outline: none;
  }
}
