@import "../../Styles/variables.scss";

form {
  padding: 2rem 0 10rem;
  box-sizing: border-box;

  .title_wrapper {
    text-align: center;
    font-size: 3.6rem;
    font-family: Bold;
    padding: 4rem;
    box-sizing: border-box;
  }

  .form_wrapper {
    max-width: 100rem;
    margin: 0 auto;
    background-color: white;
    border: 0.1rem solid $grey_1;
    border-radius: 2rem;
    padding: 6rem;
    box-sizing: border-box;
    & > div {
      padding-bottom: 6rem;
      box-sizing: border-box;
      label {
        font-family: Bold;
        font-size: 2.4rem;
        padding-bottom: 2rem;
        box-sizing: border-box;
      }
      input,
      select,
      textarea {
        font-family: Regular;
        font-size: 1.6rem;
        &::placeholder {
          color: $black_4;
        }
      }
      textarea {
        border-radius: 1rem;
        padding: 2rem 1rem;
        box-sizing: border-box;
        margin-top: 1rem;
      }
      p {
        font-family: Bold;
        font-size: 1.6rem;
        padding-bottom: 1rem;
        box-sizing: border-box;
      }
    }
    .btn_wrapper {
      text-align: center;
      padding-bottom: 0;
      input {
        font-family: Bold;
        font-size: 1.6rem;
        padding: 1.5rem 8rem;
        box-sizing: border-box;
        border-radius: 1rem;
      }
    }
  }
}
