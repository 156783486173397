@import "../../Styles/variables.scss";

.label_wrapper {
  display: flex;
  column-gap: 1rem;
  p {
    margin: 0;
  }
}

.warning {
  font-family: Regular;
  font-size: 1.4rem;
  color: rgb(222, 6, 6);
  padding: 1rem 0;
  box-sizing: border-box;
}
