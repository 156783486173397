@import "../../../Styles/variables.scss";

.advertisement {
  background-color: $blue_4;
  text-align: center;
  & > div {
    padding: 10rem 2rem;
    box-sizing: border-box;
  }
  .title_center {
    font-family: Bold;
    font-size: 4rem;
    padding-bottom: 6rem;
    box-sizing: border-box;
  }
  .box_wrapper {
    display: flex;
    gap: 2.4rem;
    text-align: center;
    word-break: keep-all;
    .box {
      flex: 1 1 0;
      .bg_wrapper {
        width: 100%;
        img {
          width: 100%;
          object-fit: cover;
          border-radius: 2rem 2rem 0 0;
        }
      }
      .text_wrapper {
        background-color: white;
        padding: 3rem;
        border: 0.05rem solid $grey_1;
        box-sizing: border-box;
        border-top: none;
        border-radius: 0 0 2rem 2rem;
        .top_text {
          font-family: Bold;
          font-size: 2rem;
          padding-bottom: 1rem;
          box-sizing: border-box;
        }
        .content_text {
          font-family: Regular;
          font-size: 1.6rem;
          color: $black_2;
          line-height: 2.6rem;
          min-height: 6rem;
        }
      }
    }
  }
  input {
    font-family: Bold;
    font-size: 1.6rem;
    padding: 2rem 6rem;
    box-sizing: border-box;
    border-radius: 1rem;
    margin-top: 6rem;
  }
}
