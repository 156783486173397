@import "../../Styles/variables.scss";

/* 
body {
  ::-webkit-scrollbar {
    display: none;
  }
} */

.top_header {
  background-color: #fff;
  border-bottom: 0.05rem solid rgb(237, 237, 237);
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  & > div {
    display: flex;
    justify-content: center;
    column-gap: 2.4rem;
    font-size: 1.2rem;
    font-family: Medium;
    letter-spacing: 0.05rem;
    color: rgb(148, 148, 148);
    padding: 1rem;
  }
}
.header_wrapper {
  background-color: white;
  border-bottom: 0.1rem solid $grey_1;
  position: sticky;
  left: 0;
  z-index: 100;
  color: $black_1;
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .left {
    flex: 1;
    img {
      width: 9rem;
    }
  }
  .center {
    flex: 4;
    font-family: Bold;
    display: flex;
    column-gap: 5rem;
    justify-content: center;
    font-size: 18px;
  }
  .right {
    display: flex;
    justify-content: right;
    flex: 1;
    select {
      color: $black_2;
      font-family: Bold;
      font-size: 1.6rem;
      border: none;
    }
    .side_menu_icon {
      width: 2.2rem;
      img {
        cursor: pointer;
        width: 100%;
      }
    }
  }
}
.side_menu {
  background-color: white;
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 1001;
  & > div:not(:last-child) {
    border-bottom: 0.1rem solid $grey_1;
  }
  .close_btn {
    text-align: right;
    padding: 2.4rem 2rem;
    box-sizing: border-box;
    img {
      width: 2.2rem;
      cursor: pointer;
    }
  }
  .nav {
    display: flex;
    flex-direction: column;
    padding: 3rem 2rem;
    box-sizing: border-box;
    & > a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: Bold;
      font-size: 1.8rem;
      padding: 1.6rem 0;
      box-sizing: border-box;
    }
  }
  .language_wrapper {
    padding: 1rem;
    box-sizing: border-box;
    select {
      width: max-content;
      border: none;
      font-size: 1.6rem;
    }
  }
}
