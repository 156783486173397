@font-face {
  font-family: "Bold";
  src: url("../Assets/Fonts/Pretendard-Bold.otf");
}
@font-face {
  font-family: "Medium";
  src: url("../Assets/Fonts/Pretendard-Medium.otf");
}
@font-face {
  font-family: "Regular";
  src: url("../Assets/Fonts/Pretendard-Regular.otf");
}

$grey_1: #dddddd;
$grey_2: #f9f9f9;
$grey_3: #555;
$grey_4: #f5f5f5;
$grey_5: #d9d9d9;

$black_1: #111;
$black_2: #444;
$black_3: #222;
$black_4: #999;
$black_6: #777;

$blue_1: #6385fc;
$blue_2: #7a8294;
$blue_3: #d1d6e8;
$blue_4: #dde3fc;
$blue_5: #f7f8fb;

$red_1: #ef4b4b;
