@import "../../Styles/variables.scss";

.category_wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: max-content;
  color: white;
  border-radius: 1rem;
  box-sizing: border-box;
  padding: 1rem;
  .category_item {
    a {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 2rem;
      padding: 0.5rem 1rem;
      box-sizing: border-box;
      & > p {
        font-family: Medium;
        box-sizing: border-box;
      }
    }
    .category_depth_1 {
      font-family: Bold;
      font-size: 1.6rem;
    }
    .category_depth_2 {
      font-size: 1.4rem;
    }
    .category_depth_3 {
      font-size: 1.2rem;
    }
    .category_depth_4 {
      font-size: 1rem;
    }
  }
}

/* ///////////////////// */
.partners_wrapper {
  width: 100%;
  border: 0.05rem solid $grey_1;
  border-radius: 1rem;
  padding: 4rem;
  padding-bottom: 2rem;
  box-sizing: border-box;
  margin-bottom: 10rem;

  .opt_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2rem;
    box-sizing: border-box;
    select {
      border: none;
      font-family: Bold;
      font-size: 1.6rem;
      padding: 1rem;
    }
    .view_wrapper {
      display: flex;
      column-gap: 1.5rem;
      img {
        cursor: pointer;
      }
    }
  }
  .partner_title {
    font-family: Bold;
    font-size: 2.4rem;
  }
  .partner_img {
    width: 100%;
    height: 50rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 1rem;
    }
  }
}

.layout_wrapper_list_view_clicked {
  .list_view_clicked {
    padding-top: 4rem;
    box-sizing: border-box;
    &:first-child {
      padding-top: 2rem;
    }
    &:not(:last-child) {
      border-bottom: 0.1rem solid $grey_1;
    }
    .partner_location {
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      font-family: Medium;
      font-size: 1.6rem;
      color: $grey_3;
      padding: 1.5rem 0;
      box-sizing: border-box;
      img {
        width: 1.8rem;
        height: 1.8rem;
      }
    }
    .partner_category_wrapper {
      display: flex;
      column-gap: 0.8rem;
      .category {
        font-family: Medium;
        font-size: 1.4rem;
        color: $black_6;
        background-color: $grey_4;
        border-radius: 0.8rem;
        padding: 0.6rem 1.2rem;
        box-sizing: border-box;
      }
    }
    .description {
      font-family: Regular;
      font-size: 1.6rem;
      line-height: 2.8rem;
      padding: 2rem 0;
      box-sizing: border-box;
    }
    .contact_wrapper {
      &,
      .contact,
      .contact > a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 1rem;
      }
      .contact {
        a {
          border-radius: 0.8rem;
          padding: 1rem 1.5rem;
          box-sizing: border-box;
          font-family: Bold;
          font-size: 1.5rem;
        }
        .website_btn {
          color: $grey_3;
          border: 0.1rem solid $grey_3;
        }
        .contact_btn {
          border: 0.1rem solid $blue_1;
          color: $blue_1;
        }
      }
    }
  }
}

.layout_wrapper_grid_view_clicked {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  .list_view_clicked {
    .link_wrapper {
      .partner_img {
        height: 25rem;
      }
      .partner_title {
        padding-top: 1.5rem;
        box-sizing: border-box;
      }
    }
    .contact_wrapper {
      .sns {
        column-gap: 1rem;
        img {
          width: 3rem;
        }
      }
    }
  }
}

.no_result {
  font-size: 2rem;
  font-family: Regular;
  padding: 3rem 0 5rem;
  text-align: center;
  box-sizing: border-box;
  span {
    font-family: Bold;
  }
}
