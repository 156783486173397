@import "../../Styles/variables.scss";

.wrapper {
  padding: 2rem 1rem;
  box-sizing: border-box;
  .column_item {
    height: 20rem;
    display: flex;
    align-items: center;
    gap: 3rem;
    padding: 2rem 0;
    box-sizing: border-box;
    &:not(:last-child) {
      border-bottom: 0.1rem solid rgb(241, 241, 241);
    }
    .thumbnail {
      width: 15%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .text_wrapper {
      width: 85%;
      .series_name {
        cursor: pointer;
        width: max-content;
        font-family: Medium;
        font-size: 1.6rem;
        color: $blue_1;
      }
      .title_wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;
        margin-top: 0.5rem;
        .title {
          font-family: Bold;
          font-size: 2.2rem;
        }
        .date {
          font-family: Medium;
          font-size: 1.4rem;
          color: $black_4;
        }
        .content {
          font-family: Medium;
          font-size: 1.6rem;
          color: $black_2;
          line-height: 2.2rem;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
