@import "../../../Styles/variables.scss";

.article_wrapper {
  display: grid;
  gap: 3rem;
  .article {
    .thumbnail_wrapper {
      width: 100%;
      height: 28rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 2rem;
      }
      img.default_img {
        object-fit: contain;
      }
    }
    .article_title {
      font-family: Medium;
      font-size: 1.8rem;
      padding: 2rem 0 1rem;
      box-sizing: border-box;
    }
    .article_date {
      font-family: Regular;
      font-size: 1.5rem;
      color: $black_4;
    }
  }
}
