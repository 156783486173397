@import "../../Styles/variables.scss";

.popup_window {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.9);
  .close_btn {
    position: absolute;
    right: 2rem;
    top: 2rem;
    z-index: 110;
    img {
      cursor: pointer;
      width: 5rem;
      height: 5rem;
    }
  }
  .gallery_popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    img {
      width: 85%;
    }
  }
}
