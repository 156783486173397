@import "../../Styles/variables.scss";

.title_wrapper {
  padding: 3rem 0;
  box-sizing: border-box;
  .title {
    font-family: Bold;
    font-size: 3.6rem;
  }
  .description {
    font-family: Medium;
    font-size: 1.6rem;
    color: $black_4;
    padding: 1rem 0;
    box-sizing: border-box;
  }
}

.flex_wrapper {
  display: flex;
  gap: 1.5rem;
}
