@import "../../Styles/variables.scss";

.article_wrapper {
  padding: 2rem 0;
  box-sizing: border-box;
  .title_wrapper {
    padding: 3rem 0;
    box-sizing: border-box;
    border-bottom: 0.1rem solid $grey_1;
    .series_name {
      font-size: 1.6rem;
      font-family: Bold;
      color: $blue_1;
      padding-bottom: 1rem;
      box-sizing: border-box;
    }
    .title {
      font-family: Bold;
      font-size: 3.6rem;
    }
    .info_wrapper {
      display: flex;
      align-items: center;
      column-gap: 2.6rem;
      padding-top: 2rem;
      box-sizing: border-box;
      & > div {
        display: flex;
        align-items: center;
        column-gap: 1rem;
        font-family: Medium;
        font-size: 1.6rem;
        color: $grey_3;
      }
      .writer_list {
        position: relative;
        display: flex;
        column-gap: 1rem;
        &::after {
          content: "";
          width: 0.1rem;
          height: 1.4rem;
          background-color: $grey_1;
          position: absolute;
          top: 50%;
          right: -1.5rem;
          transform: translate(0, -50%);
        }
        .writer:first-child::after {
          content: "·";
          padding-left: 1rem;
          box-sizing: border-box;
        }
      }
    }
  }
  .column_writer_wrapper {
    text-align: center;
    padding-top: 5rem;
    box-sizing: border-box;
    img {
      width: 100%;
      max-width: 40rem;
    }
  }
  .text {
    padding: 3rem 0;
    box-sizing: border-box;
  }
  .btn_wrapper {
    text-align: center;
    padding: 5rem 0 0;
    box-sizing: border-box;
    input {
      font-family: Bold;
      font-size: 1.8rem;
      border-radius: 1rem;
      padding: 1.5rem 5rem;
      box-sizing: border-box;
    }
  }
}

.swiper_wrapper {
  padding: 5rem 0;
  box-sizing: border-box;
}
