@import "../../../Styles/variables.scss";

.popular_news {
  background-color: $blue_4;
  padding: 3rem 0;
  box-sizing: border-box;
  .column_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    .latest_wrapper {
      border-radius: 2rem;
      background-color: #fff;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
    .rank_wrapper {
      border-radius: 2rem;
      background-color: #fff;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      border-radius: 2rem;
      padding: 2rem 3rem;
      box-sizing: border-box;
      .rank_item {
        display: flex;
        align-items: flex-start;
        column-gap: 1rem;
        &:not(:last-child) {
          margin-bottom: 1.6rem;
        }
        .rank_num {
          font-family: Medium;
          font-size: 1.5rem;
          background-color: $blue_1;
          padding: 0.2rem 0.8rem 0.2rem 0.7rem;
          color: white;
          box-sizing: border-box;
          border-radius: 0.6rem;
        }
        .rank_article_wrapper {
          .article_title {
            font-family: Bold;
            font-size: 1.8rem;
            margin-bottom: 1rem;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: no-wrap;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
          .writer {
            font-family: Regular;
            font-size: 1.5rem;
            color: $black_4;
          }
        }
      }
    }
    .banner_wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 2rem;
      & > div {
        height: 24rem;
        border-radius: 2rem;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        & > div {
          /* swiperSlider */
          padding: 0;
          margin: 0;
          height: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .popular_news {
    .content_wrapper {
      flex-direction: column !important;
    }
  }
}
