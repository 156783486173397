@import "../../../Styles/variables.scss";

.top_container {
  .info_wrapper {
    display: flex;
    align-items: center;
    column-gap: 4rem;
    & > div {
      width: 100%;
    }
    .thumbnail_wrapper {
      height: 36rem;
      img {
        border-radius: 2rem;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .default_img {
        object-fit: contain;
      }
    }
    .text_wrapper {
      & > div {
        margin-bottom: 2rem;
      }
      .title {
        font-family: Bold;
        font-size: 3.6rem;
      }
      .location {
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        font-family: Medium;
        font-size: 1.6rem;
        color: $grey_3;
        img {
          width: 1.8rem;
        }
      }
    }
  }
  .ad_banner_wrapper {
    margin: 4rem 0;
  }
}
