html {
  font-size: 62.5%;
}
body {
  padding: 0;
  margin: 0;
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  html {
    font-size: 56.25%;
  }
}

@media screen and (max-width: 768px) {
  html {
    font-size: 50%;
  }
}
