@import "../../Styles/variables.scss";

.wrapper {
  display: grid;
  padding: 3rem 0;
  box-sizing: border-box;
  .gallery_item {
    &:last-child {
      border: none !important;
    }
    .thumbnail {
      height: 30rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .title {
      font-family: Bold;
      font-size: 1.6rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-top: 1rem;
      margin-bottom: 2rem;
      box-sizing: border-box;
    }
  }
}
