@import "../../Styles/variables.scss";

.footer_wrapper {
  padding: 3rem 0;
  box-sizing: border-box;
  background-color: $black_3;
  color: white;

  .footer_logo_wrapper {
    img {
      width: 12rem;
    }
  }
  .f_top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 0.1rem solid $grey_3;
    padding-bottom: 3rem;
    .f_top_left {
      font-family: Regular;
      font-size: 1.5rem;
      padding-top: 2rem;
      box-sizing: border-box;
      .text_wrapper {
        .text_row {
          display: flex;
          column-gap: 2rem;
          padding-bottom: 1rem;
          box-sizing: border-box;
          .text_item {
            position: relative;
          }
          .text_item:not(:last-child)::after {
            position: absolute;
            top: 50%;
            right: -1rem;
            transform: translate(0, -50%);
            content: "";
            width: 0.05rem;
            height: 1rem;
            background-color: $grey_3;
          }
        }
      }
      .bottom_text {
        color: $black_4;
        line-height: 2.4rem;
      }
    }
    .f_top_right {
      box-sizing: border-box;
      .icon_wrapper {
        display: flex;
        align-items: center;
        column-gap: 1rem;
        font-family: Regular;
        font-size: 1.4rem;
        padding: 0.5rem 0;
        box-sizing: border-box;
      }
    }
  }
  .f_bottom {
    padding-top: 3rem;
    box-sizing: border-box;
    font-family: Regular;
    font-size: 1.4rem;
    color: $grey_3;
    display: grid;
    .f_bottom_right {
      text-align: right;
      padding-bottom: 1rem;
      box-sizing: border-box;
      a:last-child {
        margin-left: 2rem;
      }
    }
  }
}
