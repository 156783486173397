@import "../../Styles/variables.scss";

.banner {
  width: 100%;
  img {
    background-color: #fff;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.swiperButtonPrev,
.swiperButtonNext {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 4rem;
  height: 4rem;
  background: #fff;
  border: 0.1rem solid $grey_1;
  z-index: 10;
  & > img {
    width: 2rem;
    height: 2rem;
  }
}

.swiperButtonPrev {
  left: 0;
  .prev_btn {
    position: absolute;
    bottom: 50%;
    right: 50%;
    transform: rotate(180deg) translate(-50%, -50%);
  }
}

.swiperButtonNext {
  right: 0;
  .next_btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
