@import "../../../Styles/variables.scss";

.youtube_profile_wrapper {
  display: flex;
  align-items: center;
  img {
    width: 16rem;
    height: 16rem;
    border-radius: 50%;
  }
  .profile_info_wrapper {
    padding: 0 2rem;
    box-sizing: border-box;
    .user_title {
      font-family: Bold;
      font-size: 2.8rem;
    }
    .user_info_wrapper {
      display: flex;
      column-gap: 0.8rem;
      padding: 1.5rem 0;
      box-sizing: border-box;
      & > div {
        border: 0.1rem solid $grey_1;
        font-family: Medium;
        font-size: 1.5rem;
        color: $grey_3;
        padding: 0.5rem 0.8rem;
        box-sizing: border-box;
        border-radius: 0.5rem;
      }
    }
    .user_description {
      font-family: Regular;
      font-size: 1.5rem;
      line-height: 2.8rem;
    }
  }
}
