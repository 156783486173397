@import "../../Styles/variables.scss";

.contact_popup {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 3rem;
  box-sizing: border-box;
  .popup_window {
    background-color: white;
    width: 100%;
    max-width: 90rem;
    height: 100%;
    margin: 0 auto;
    padding: 5rem;
    box-sizing: border-box;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    .close_btn {
      text-align: right;
      img {
        cursor: pointer;
        width: 2rem;
      }
    }
    .form_wrapper {
      .title {
        font-family: Bold;
        font-size: 2.4rem;
      }
      form {
        padding: 0;
        .input_wrapper {
          padding: 1rem 0;
          box-sizing: border-box;
          label {
            font-family: Bold;
            font-size: 1.6rem;
            padding: 1rem 0;
            box-sizing: border-box;
          }
          input,
          textarea {
            font-family: Regular;
            font-size: 1.4rem;
            padding: 1rem 1rem;
            box-sizing: border-box;
            border-radius: 1rem;
            &::placeholder {
              color: $black_4;
            }
          }
          textarea {
            margin-top: 1rem;
          }
        }
      }
    }
    .button_wrapper {
      padding-top: 2rem;
      box-sizing: border-box;
      input {
        font-family: Bold;
        font-size: 1.6rem;
        padding: 1rem 8rem;
        box-sizing: border-box;
        border-radius: 1rem;
      }
    }
  }
}
