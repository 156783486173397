@import "../../Styles/variables.scss";

.table {
  .table_header {
    display: grid;
    text-align: center;
    font-family: Bold;
    font-size: 1.6rem;
    border-top: 0.1rem solid rgb(190, 190, 190);
    background-color: #f4f4f4;
    padding: 2rem 0;
    box-sizing: border-box;
  }
  .table_content {
    .table_row {
      display: grid;
      text-align: center;
      font-family: Medium;
      font-size: 1.6rem;
      padding: 2rem 0;
      box-sizing: border-box;
      border-bottom: 0.1rem solid $grey_1;
      color: $grey_3;
      .fixed_cell {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
