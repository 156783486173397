@import "../../../Styles/variables.scss";

.hospital {
  padding-bottom: 5rem;
  box-sizing: border-box;
  .category_wrapper {
    .name_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5rem 0 3rem;
      box-sizing: border-box;
      column-gap: 1rem;
      .category_name {
        font-size: 2.6rem;
        font-family: Bold;
        span {
          color: $blue_1;
        }
      }
      .more_btn {
        font-family: Bold;
        font-size: 1.6rem;
        color: $black_2;
        border: 0.1rem solid $black_4;
        padding: 0.5rem 2rem;
        box-sizing: border-box;
        border-radius: 5rem;
        min-width: max-content;
      }
    }

    .thumbnail_wrapper {
      display: flex;
      gap: 3rem;
      a {
        width: 100%;
        height: 25rem;
        background-color: #fff;
        border-radius: 2rem;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 2rem;
        }
      }
    }
  }
}
