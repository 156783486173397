@import "../../Styles/variables.scss";

.label_wrapper {
  display: inline-block;
  background-color: white;
  border-radius: 1rem;
  border: 0.1rem solid $grey_5;
  padding: 1.5rem;
  box-sizing: border-box;
  text-align: center;
  width: 30rem;
  .label_title {
    font-family: Bold;
    font-size: 1.8rem;
    color: $red_1;
    padding-bottom: 1rem;
    box-sizing: border-box;
  }
  .label_description {
    font-family: Medium;
    font-size: 1.4rem;
    color: $black_3;
  }
  &::before {
    content: "";
    position: absolute;
    bottom: -1.6rem;
    left: 50%;
    transform: translate(-50%, 0);
    width: 0;
    height: 0;
    border-bottom: 0.8rem solid transparent;
    border-top: 0.8rem solid $grey_5;
    border-left: 0.7rem solid transparent;
    border-right: 0.7rem solid transparent;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: -1.5rem;
    left: 50%;
    transform: translate(-50%, 0);
    width: 0;
    height: 0;
    border-bottom: 0.8rem solid transparent;
    border-top: 0.8rem solid white;
    border-left: 0.7rem solid transparent;
    border-right: 0.7rem solid transparent;
  }
}
