@import "../../Styles/variables.scss";

.nav_wrapper {
  border-bottom: 0.1rem solid $grey_1;
  .flex_wrapper {
    display: flex;
    .nav_item {
      cursor: pointer;
      font-family: Bold;
      font-size: 2rem;
      color: $black_4;
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
}
